import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import _omit from 'lodash/omit';
import { useContext } from 'react';

import api from '@/core/api';
import { usePathParameters } from '@/core/hooks/use-path-parameters/use-path-parameters';
import { components } from '@/core/types/api';
import { ColumnsConfigContext } from '@/observe/contexts/columns-config-context';
import { useObserveStore } from '@/observe/stores/observe.store';
import { ObserveFilterType } from '@/observe/types/observe-store.types';
import { convertFilterToFieldType } from '@/observe/utils/convert-filter-to-field-type/convert-filter-to-field-type';
import { getTimeRange } from '@/observe/utils/get-time-range/get-time-range';

import { useQueryParameters } from '../../use-query-parameters/use-query-parameters';

// TODO: remove once API changes field name. JavaScript objects expect type of `constructor` as string & Function
export type ObserveRow = Omit<
  components['schemas']['TransactionRecordDB'],
  'constructor'
> & {
  node_name: string | null | undefined;
  // TODO: API will rename `metric_info` to just `metrics`
  metrics: components['schemas']['TransactionRecordDB']['metric_info'];
};

// API Path
export const ROWS_PATH = '/projects/{project_id}/observe/rows';

// Construct the sort spec if the field is nested under `objectAccessor`
export function getSortBy({
  objectAccessor,
  filterType,
  sortBy,
  sortDirection
}: {
  objectAccessor: string | undefined;
  filterType: ObserveFilterType | undefined;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
}): components['schemas']['SortClause'] {
  if (objectAccessor != null) {
    return {
      col_name: objectAccessor,
      json_field: sortBy,
      json_field_type: convertFilterToFieldType(filterType),
      sort_dir: sortDirection
    };
  } else {
    return {
      col_name: sortBy,
      sort_dir: sortDirection
    };
  }
}

export const useRows = ({
  chainId,
  disableRefreshInterval
}: {
  chainId?: string | null;
  disableRefreshInterval?: boolean;
}) => {
  const queryClient = useQueryClient();

  // Parameters
  const { projectId } = usePathParameters();

  const { get } = useQueryParameters();
  const promptMonitorFilters = get('promptMonitorFilters');
  const pageSize = get('pageSize');
  const showChains = get('showChains');
  const sortBy = get('sortBy');
  const sortDirection = get('sortDirection');
  const timeRange = get('timeRange');
  const refreshInterval = get('refreshInterval');

  const isSubChainRequest = chainId != null;

  // For sub chain requests that have a chain id, we always include
  // chains so that the rows are limited to the ones belonging to the chain
  const includeChains = showChains || isSubChainRequest;

  const { lastUpdatedTime, setLastUpdatedTime } = useObserveStore((s) => ({
    lastUpdatedTime: s.lastUpdatedTime,
    setLastUpdatedTime: s.actions.setLastUpdatedTime
  }));

  const { columnsConfig } = useContext(ColumnsConfigContext);

  const sortSpec: components['schemas']['SortClause'][] = [];
  // If requesting a sub chain node (i.e. expanding a chain row), force the sorting to be by creation time
  // NOTE: for chain requests at the root level (i.e. chainId is null), we allow using the sortBy parameter
  if (isSubChainRequest) {
    sortSpec.push({
      col_name: 'created_at',
      sort_dir: 'asc'
    });
  } else if (sortBy != null) {
    // Find corresponding column config to see if the sort spec is a nested field under `objectAccessor`
    const columnConfig = Object.values(columnsConfig).find(
      (columnConfig) => columnConfig.accessor === sortBy
    );
    sortSpec.push(
      getSortBy({
        objectAccessor: columnConfig?.objectAccessor,
        filterType: columnConfig?.filterType,
        sortBy,
        sortDirection
      })
    );
  }

  // Dependencies
  const dependencies = [projectId];
  const enabled = dependencies.every(Boolean); // Only enable if all dependencies are truthy

  // Query
  const queryKey = [
    ROWS_PATH,
    {
      projectId,
      promptMonitorFilters,
      chainId,
      timeRange,
      refreshInterval,
      sortSpec,
      includeChains,
      pageSize
    }
  ];
  const queryFn = async ({ pageParam = 0 }) => {
    // These parameters need to be calculated within the query function scope in order to
    // use the latest time for querying when manually refreshing via query invalidation
    // NOTE: sub chain requests should continue to use the last updated time from the root query
    const queryTime = isSubChainRequest
      ? lastUpdatedTime
      : new Date().getTime();
    const { startTime, endTime } = getTimeRange(queryTime, timeRange);

    const res = await api.POST(ROWS_PATH, {
      params: {
        query: {
          start_time: startTime,
          end_time: endTime,
          limit: pageSize,
          offset: pageParam,
          include_chains: includeChains,
          chain_id: chainId
        },
        path: {
          project_id: projectId!
        }
      },
      body: {
        filters: promptMonitorFilters,
        sort_spec: sortSpec
      }
    });

    // NOTE: sub chain requests do not set the last updated time
    if (!isSubChainRequest) {
      setLastUpdatedTime(queryTime);
    }

    const rows: ObserveRow[] | undefined = res?.data?.rows?.map((value) => ({
      // TODO: remove `constructor` because it causes equality checks using isPlainObject to fail
      ..._omit(value, ['constructor']),
      // TODO: API will rename `metric_info` to just `metrics`
      metrics: value.metric_info,
      // TODO: remove once API changes field name. JavaScript objects expect type of `constructor` as string & Function
      node_name: value.constructor
    }));

    rows?.forEach((row) => {
      queryClient.setQueryData(
        [ROWS_PATH, { projectId, nodeId: row.node_id, timeRange }],
        row
      );
    });

    return { ...res.data, rows };
  };

  // Response
  return useInfiniteQuery({
    queryKey,
    queryFn,
    enabled,
    initialPageParam: 0,
    refetchInterval: disableRefreshInterval ? undefined : refreshInterval,
    // This forces the cache to always update with the latest response data
    structuralSharing: false,
    gcTime: 2 * 60 * 1000, // 2 minutes
    getNextPageParam: (lastPage) => {
      return lastPage?.paginated ? lastPage?.next_starting_token : undefined;
    }
  });
};
