import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import api from '@/core/api';

export const GET_CURRENT_USER = '/current_user';

/**
 * useCurrentUser
 *
 */
export const useCurrentUser = () => {
  const session = useSession();

  const fetchCurrentUser = async () => {
    const res = await api.GET(GET_CURRENT_USER);
    const data = res?.data;
    const name =
      data?.first_name && data?.last_name
        ? `${data.first_name} ${data.last_name}`
        : '';
    const resWithName = {
      ...data,
      name
    };

    return resWithName;
  };

  const response = useQuery({
    queryKey: [GET_CURRENT_USER, { status: session.status }],
    queryFn: fetchCurrentUser
  });

  return response;
};
