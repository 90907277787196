import {
  Box,
  HoverCard,
  HoverCardProps,
  ScrollArea,
  useMantineTheme
} from '@mantine/core';
import { CSSProperties } from 'react';

import { Z_INDEX } from '@/core/constants/z-index.constants';

export interface MetricPopoverProps extends HoverCardProps {
  header?: string | JSX.Element;
  body?: string | JSX.Element;
  variant?: 'dark' | 'light';
}

export const MetricPopover = ({
  header,
  body,
  variant = 'dark',
  ...props
}: MetricPopoverProps) => {
  // Hooks
  const { colors } = useMantineTheme();

  let styles: CSSProperties = {
    border: 'none',
    boxShadow: '0px 0px 12px 0px rgba(28, 27, 34, 0.12)',
    overflow: 'hidden',
    padding: 0
  };

  const primary = {
    dark: colors.gray[3],
    light: colors.gray[6]
  };

  const secondary = {
    dark: colors.contrast[3],
    light: colors.contrast[9]
  };

  // Dark variant styles
  if (primary[variant] && secondary[variant]) {
    styles = {
      ...styles,
      backgroundColor: secondary[variant],
      color: primary[variant]
    };
  }

  return (
    <HoverCard
      withinPortal
      data-testid='metric-popover'
      openDelay={400}
      portalProps={{
        // Heads up: This optimizes the amount of DOM nodes created by portals by dedicating a single portal for all <MetricPopovers />.
        // If we ever need to render multiple popovers at once, we'll need to adjust this.
        // See: `mantine-and-react-query-provider.tsx`.
        target: '._mantine-metric-popover'
      }}
      position='right-start'
      width={300}
      zIndex={Z_INDEX.TOOLTIPS}
      {...props}
    >
      <HoverCard.Target>
        <Box display='inline-flex' maw='100%'>
          {props.children}
        </Box>
      </HoverCard.Target>
      <HoverCard.Dropdown
        maw={300}
        style={styles}
        w='fit-content'
        onClick={(e) => {
          // Stops mouse clicks from passing through to DOM elements behind the dropdown
          e.stopPropagation();
        }}
      >
        {header && (
          <Box bg={colors.contrast[2]} display='flex' p='0.325rem' py='xs'>
            {header}
          </Box>
        )}
        <ScrollArea.Autosize
          mah={400}
          style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          {body ?? null}
        </ScrollArea.Autosize>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
