globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"5b7a1319c06f546e0721de9854bbc45e665bc640"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

// Fetch ENV variables from server, then initialize Sentry
axios.get('/api/config').then((res) => {
  const { GALILEO_CUSTOMER_NAME, SENTRY_DSN, SENTRY_RELEASE } = res.data;
  
  Sentry.init({
    dsn: SENTRY_DSN,
    enabled: GALILEO_CUSTOMER_NAME !== 'localhost',
    environment: GALILEO_CUSTOMER_NAME,
    ignoreErrors: [
      'Abort fetching component for route', // Error occurs when clicking a link twice, no need to log
      'TypeError: Failed to fetch', // Error occurs when clicking a link twice, no need to log
    ],
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error']
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 599]
      }),
      Sentry.extraErrorDataIntegration({depth: 4}),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    profilesSampleRate: 0,
    release: SENTRY_RELEASE,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.05, // 5% of errors will be replayed. Bump this up to 1.0 once we have better handling on Sentry errors.
    tracesSampleRate: 0.1,
  });
})
